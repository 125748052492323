<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'
import { FieldValueType, useFieldCast } from '@manager'

defineProps<{
  commencementDate?: string | Date | null
  expiryDate?: string | Date | null
  baseRent?: number | null
  leaseDateFormat: string
}>()

const emit = defineEmits<{
  confirm: [value: { baseRent: number }]
  close: []
}>()

const dayjs = useDayjs()

// FORM REF
const form = ref<{ node: FormKitNode }>()

// SUBMIT HANDLER
const onSubmit = ({ baseRent }: { baseRent: number }) => {
  emit('confirm', { baseRent })
}
const { castPlugin: castBaseRent } = useFieldCast({
  valueType: FieldValueType.CURRENCY,
})
</script>

<template>
  <ModalBase
    title="Option Exercise"
    primary-button-text="Save"
    primary-button-tooltip="You will be redirected to the new Lease Document"
    modal-class="max-w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    @confirm="form?.node.submit()"
    @close="$emit('close')"
  >
    <div>
      <p class="text-center font-bold">You are exercising an option.</p>
      <p class="text-center">
        A new Lease will be created, please review the new dates:
      </p>
    </div>

    <FormKit
      ref="form"
      type="form"
      :actions="false"
      form-class="p-4 mt-4 rounded-lg bg-gray-850"
      :value="{ baseRent }"
      @submit="onSubmit"
    >
      <div class="| null-cols-1 grid w-full gap-4">
        <!-- EXERCISE - START DATE -->
        <div>
          <div class="text-sm text-white">New Lease Commencement Date</div>
          <div class="mt-1 flex items-center">
            <Icon name="calendar" class="mr-2" />
            <time>
              {{
                commencementDate
                  ? dayjs(commencementDate).format(leaseDateFormat)
                  : '-'
              }}
            </time>
          </div>
        </div>
        <div>
          <div class="text-sm text-white">New Lease Expiry Date</div>
          <div class="mt-1 flex items-center">
            <Icon name="calendar" class="mr-2" />
            <time>
              {{ expiryDate ? dayjs(expiryDate).format(leaseDateFormat) : '-' }}
            </time>
          </div>
        </div>
        <FormKit
          type="currency"
          currency="AUD"
          display-locale="en-AU"
          decimals="2"
          name="baseRent"
          label="New Lease Base Rent"
          outer-class="w-full"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          validation="required"
          :plugins="[castBaseRent]"
        />
      </div>
    </FormKit>
  </ModalBase>
</template>
